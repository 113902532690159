/* CSS File d'attente */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Montserrat', sans-serif;
    background-color: #FFF;
}

* {
    box-sizing: border-box;
}

.modal-header {
    display: none;
}

.modal-body {
    padding-bottom: 0;
}

.modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    padding-top: 0;
}

.modal-dialog.confirm-enter {
    max-width: 640px;
    text-align: center;
}

.modal-dialog.confirm-enter h1 {
    font-size: 20px;
    font-weight: 600;
    color: #b71413;
}

header {
    height: 150px;
    display: flex;
    justify-content: center;
}

.logo-wrapper {
    display: flex;
    align-items: flex-end;
}

.logo-wrapper img {
    height: 8rem;
}

.first_info_block {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2.first_info {
    font-size: 20px;
    font-weight: 600;
    color: #b71413;
    margin: 1.5rem 0;
}

.first_info_sub {
    display: none;
}

.subtitle {
    font-size: 12px;
    font-weight: 600;
    margin-top: 48px;
}

a {
    text-decoration: underline;
}

a:hover {
    text-decoration: none;
}

.main_block {
    text-align: center;
    max-width: 820px;
    margin: 2rem auto;
    font-size: 14px;
}

.description-alert {
    text-align: left;
    background-color: #f5f5f5;
    padding: 12px 24px;
    border-radius: 8px;
    margin-top: 96px;
}

.description-alert p {
    font-size: 12px;
    margin: 0;
    white-space: pre-line;
}

.description-alert button {
    color: #b71413 !important;
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
    background: transparent !important;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.description-alert button:before {
    content: '';
    height: 12px;
    width: 12px;
    display: block;
    margin-right: 8px;
    background: url('../images/icon-alert.svg') no-repeat;
    background-size: contain;
    background-position: center;
}

.description-alert button span {
    text-decoration: underline;
}

.description-alert button:hover span {
    text-decoration: none;
}

.link {
    color: #b71413;
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
}

.link a {
    margin-left: 8px;
    color: #b71413;
}

#progress {
    margin: 20px 0;
    height: 8px;
    position: relative;
}

.detach-modal {
    font-size: 14px;
}

.detach-modal .modal-body {
    padding-bottom: 1rem;
}

.detach-modal label {
    font-size: 14px;
    font-weight: 600;
}

.detach-modal input {
    height: 48px;
}

.confirm-passing {
    font-size: 14px;
}

.confirm-passing .btn-timer {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
}

@media (max-width: 991.98px) {
    header {
        height: 60px;
    }

    main {
        margin: 48px 24px;
    }
}
